const API_BASE_URL = process.env.REACT_APP_WSHOST_URL;

export const saveMeeting = async ({ meetingId, participantName }) => {
  if (API_BASE_URL === undefined) {
    console.error("REACT_APP_WSHOST_URL is not defined");
    return;
  }
  const url = `${API_BASE_URL}/api/meetings`;
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ meetingId, participantName }),
    /* cors is enabled on the server side */
    mode: "cors",
  };

  console.log({ url, options });
  const response = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));

  console.log({response});
  return response;
}
