import React from 'react';
import PO3DataView from './PO3DataView';
import BodyComposition from './BodyComposition';
import BloodPressureUI from './BloodPressureUI';

const ConsultationDataView = ({ data }) => {
  return (
    <div
      // className="flex-col rounded-md bg-gray-500"
      className="flex-col rounded-md bg-white text-gray"
      style={{
        position: 'fixed',
        top: '20%',
        right: 0,
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        width: '500px',
        height: '500px',
        zIndex: 9999,
      }}
    >
      <>
        {data && (
          data.type === "PO3" ? (
            <PO3DataView data={data.response} />
          ) :
            data.type === "BP5S" ? (
              <BloodPressureUI event={data.response} />
            ) :
              data.type === "HS2S" ? (
                <BodyComposition data={data} />
              ) : null)}
      </>
    </div>
  );
};

export default ConsultationDataView;
