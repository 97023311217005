import React, {useEffect, useState} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useAtom } from "jotai";
import { pulseWaveAtom } from '../../state/pulsewave';

const PulseWaveChart = ({ pulseWaveData }) => {
  const [data, setPulseWaveData] = useAtom(pulseWaveAtom);
  const [pulseWave, setPulseWave] = useState([]);

  useEffect(() => {
    setPulseWaveData((prev) => {
      const next = [...prev, ...pulseWaveData];
      return next.slice(Math.max(next.length - 200, 0));
    });
  }, [pulseWaveData, setPulseWaveData]);

  useEffect(() => {
    setPulseWave(data.map((value, index) => ({ name: index, pv: value })));
  }, [data]);

  console.log({ pulseWave });
  return (
    <LineChart width={400} height={200} data={pulseWave}>
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      {/* <XAxis dataKey="name" /> */}
      {/* <YAxis /> */}
      {/* <Tooltip /> */}
      {/* <Legend /> */}
      <Line type="monotone" dataKey="pv" stroke="#8884d8" />
    </LineChart>
  );
};

export default PulseWaveChart;
