import React from 'react';
import './BodyComposition.css';
import { DateTime } from 'luxon';

const BodyComposition = ({ data }) => {
  const {
    weight = 0,
    height = 0,
    protein_rate = 0,
    muscle_mas: muscleMass = 0,
    body_fit_percentage = 0,
    body_water_rate = 0,
    bone_salt_content = 0,
    visceral_fat_grade = 0,
    physical_age = 0,
  } = data.data_body_fat_result;

  const bodyFitPercentage = parseFloat(body_fit_percentage);
  const bodyWaterRate = parseFloat(body_water_rate);
  const proteinRate = parseFloat(protein_rate);
  const boneSaltContent = parseFloat(bone_salt_content);
  const visceralFatGrade = parseFloat(visceral_fat_grade);
  const physicalAge = parseFloat(physical_age);
  
  // Calculating BMI
  const heightInMeters = height / 100;
  const bmi = weight / (heightInMeters * heightInMeters);

  // Formatting date and time from measure_time
  const measureTime = new Date(data.data_body_fat_result.measure_time * 1000);
  // formattedDate like `8 Jan. 2021`
  const formattedDate = DateTime.fromJSDate(measureTime).toFormat('d LLL. yyyy');
  // formattedTime like 19:53
  const formattedTime = DateTime.fromJSDate(measureTime).toFormat('HH:mm');
  // const formattedDate = measureTime.toLocaleDateString('fr-FR');
  // const formattedTime = measureTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  return (
    <div className="max-w-sm mx-auto my-8 p-4">
      {/* Date and Time */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-sm text-gray-500">{formattedDate}</div>
        <div className="text-sm text-gray-500">{formattedTime}</div>
      </div>

      {/* BMI Section */}
      <div className="mb-4">
        <div className="text-xs uppercase text-gray-500 mb-1">Poids (kg)</div>
        <div className="text-3xl font-bold">{weight.toFixed(1)} <span className="text-sm font-normal">± 0.5</span></div>
        <div className="text-xs uppercase text-gray-500 mb-1">IMC</div>
        <div className="text-3xl font-bold">{bmi.toFixed(1)}</div>
        <div className="w-full progress-bar-bg rounded-full h-2.5 my-2">
          <div className="progress-bar-fill" style={{ width: `${(bmi / 40) * 100}%` }}></div>
        </div>
        <div className="text-xs text-center">Normal</div>
      </div>

      {/* Body Composition Section */}
      <div className="mb-4">
        <div className="grid grid-cols-3 gap-2 text-xs">
          <div>
            <div className="text-gray-500 uppercase">Graisse</div>
            <div className="font-bold">{bodyFitPercentage.toFixed(1)} %</div>
          </div>
          <div>
            <div className="text-gray-500 uppercase">Masse maigre</div>
            <div className="font-bold">{(weight * (1 - bodyFitPercentage / 100)).toFixed(1)} kg</div>
          </div>
          <div>
            <div className="text-gray-500 uppercase">Eau corporelle</div>
            <div className="font-bold">{bodyWaterRate.toFixed(1)} %</div>
          </div>
        </div>
      </div>

      {/* Metabolic Rates Section */}
      <div className="mb-4">
        <div className="grid grid-cols-2 gap-2 text-xs">
          <div>
            <div className="text-gray-500 uppercase">Protein Rate</div>
            <div className='font-bold'>{proteinRate.toFixed(1)} %</div>
          </div>
          <div>
            <div className="text-gray-500 uppercase">Physical Age</div>
            <div className="font-bold">{physicalAge.toFixed(0)}</div>
          </div>
        </div>
      </div>

      {/* Muscle Mass Section */}
      <div className="mb-4">
        <div className="grid grid-cols-3 gap-2 text-xs">
          <div>
            <div className="text-gray-500 uppercase">Masse musculaire</div>
            <div className="font-bold">{muscleMass} kg</div>
          </div>
          <div>
            <div className="text-gray-500 uppercase">Masse viscérale</div>
            <div className="font-bold">{visceralFatGrade}</div>
          </div>
          <div>
            <div className="text-gray-500 uppercase">Masse osseuse</div>
            <div className="font-bold">{boneSaltContent} kg</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyComposition;
