import React, { useState, useEffect } from 'react';
import './BloodPressureUI.css'; // Assuming you have a CSS file for styling

const BloodPressureUI = ({ event }) => {
  const [latestBP, setLatestBP] = useState({ sys: 0, dia: 0, heartRate: 0 });

  console.log({ event });

  useEffect(() => {
    if (event.action === 'online_result_bp') {
      setLatestBP({
        sys: event.sys,
        dia: event.dia,
        heartRate: event.heartRate,
      });
    }
  }, [event]);

  return (
    <div className="bp-container rounded-md">
      <div className="bp-header">Blood Pressure Monitor</div>
      <div className="bp-reading">
        <div className="bp-value bp-sys">{latestBP.sys} <span className="bp-unit">mmHg</span></div>
        <div className="bp-label">SYS</div>
      </div>
      <div className="bp-reading">
        <div className="bp-value bp-dia">{latestBP.dia} <span className="bp-unit">mmHg</span></div>
        <div className="bp-label">DIA</div>
      </div>
      <div className="bp-reading">
        <div className="bp-value bp-pulse">
          <div className="heart-icon-container">
              <div className={`heart-icon ${event.heartbeat ? 'beat' : ''}`}> ♡ </div>
          </div>
          {latestBP.heartRate} <span className="bp-unit">Beats/Min</span>
        </div>
        <div className="bp-label">PULSE</div>
      </div>
      <div
      className="rounded-md"
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          border: '2px solid white',
          padding: '20px',
          width: '100px',
          textAlign: 'center',
        }}
      >
        <div className='bp-value'>{event.pressure ?? 0}</div>
      </div>
    </div>
  );
};

export default BloodPressureUI;
