import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { useAtom } from "jotai";
import { meetingAtom } from "./state/meeting";
import PulseWaveChart from "./components/PulseWave";
import WaitingToJoinScreen from "./components/screens/WaitingToJoinScreen";
import { getToken, validateMeeting } from "../src/api";

import { socket } from "./socket";

const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;

const pulseWaveData = [
  { pulseWave: [726, 1277, 1570] },
  // Add other data entries here...
];

const App = () => {
  const [meetingDetails, setMeetingDetails] = useAtom(meetingAtom);
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [isCheckingParticipantName, setIsCheckingParticipantName] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const meetingIdFromUrl = urlParams.get('meetingId');
  const participantNameFromUrl = urlParams.get('participantName');
  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(() => {
    function onConnect() {
      console.log("APP: ws socket connected");
      setIsSocketConnected(true);
    }

    function onDisconnect() {
      console.log("APP: ws socket disconnected");
      setIsSocketConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  useEffect(() => {
    const JoinMeeting = async (id) => {
      const token = await getToken();
      const valid = await validateMeeting({
        roomId: id,
        token,
      });
      if (valid) {
        setToken(token);
        setMeetingId(id);
        setMeetingDetails({
          token,
          meetingId: id,
          name: participantNameFromUrl,
        });
        // if (videoTrack) {
        //   videoTrack.stop();
        //   setVideoTrack(null);
        // }
        setMeetingStarted(true);
        // setParticipantName("");
      } else alert("Invalid Meeting Id");
    }
    if (participantNameFromUrl) {
      setParticipantName(participantNameFromUrl);
    }
    if (meetingIdFromUrl && participantNameFromUrl) {
      JoinMeeting(meetingIdFromUrl);
    }
    setIsCheckingParticipantName(false);
    console.log({ meetingIdFromUrl, participantNameFromUrl });
  }, []);

  console.log({ meetingDetails });

  return (
    <>
      {/* <PulseWaveChart data={pulseWaveData} /> */}
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId: meetingDetails.meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: true,
            }}
            token={meetingDetails.token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              isSocketConnected={isSocketConnected}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : isCheckingParticipantName || participantName === 'doctor' ? (<WaitingToJoinScreen/>) : (
        <JoiningScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            setMeetingStarted(true);
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
        />
      )}
    </>
  );
};

export default App;
