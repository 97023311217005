import React from 'react';
import './PO3DataView.css';
import PulseWaveChart from './PulseWaveChart';

const PO3DataView = ({ data }) => {
  return (
    <div className="health-data-container">
      <div className="vital-signs">
        <div className="heart-rate">
          <span className="icon">❤️</span>
          <span className="value">{data.heartrate ?? 0} BPM</span>
        </div>
        <div className="blood-oxygen">
          <span className="icon">🫁</span>
          <span className="value">{data.bloodoxygen ?? 0}%</span>
        </div>
      </div>
      <div className="pulse-wave-graph">
        <PulseWaveChart pulseWaveData={data.pulseWave ?? []} />
        Pulse Wave Graph
      </div>
      <div className="additional-info">
        <div>Pulse Index: {data.pi ?? 0}</div>
        <div>Pulse Strength: {data.pulsestrength ?? 0}</div>
      </div>
    </div>
  );
};

export default PO3DataView;
